<script lang="ts">
    import {MediaUploadFormEntry} from "./interface"
    import SingleUploadType from "./SingleUploadType.svelte"
    import MultiUploadType from "@shared/Form/MediaUploadType/MultiUploadType.svelte"

    export let entry: MediaUploadFormEntry
    export let neutral: boolean = false
</script>


<div {...entry.rowAttr} class="flex flex-col">
    {#if entry.extra.max_files === 1}
        <SingleUploadType entry={entry} on:stitch on:reset/>
    {:else}
        <MultiUploadType neutral={neutral} entry={entry} on:stitch/>
    {/if}
</div>
