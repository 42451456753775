<script lang="ts">
    import {MediaFormEntry, MultiUploadEntry} from "./interface"
    import {fade, slide} from "svelte/transition"
    import tooltip from "@utils/Action/Tooltip"
    import {createEventDispatcher} from "svelte"
    import {getPreviewIcon, hasBrowserNativePreview, isImage} from "@shared/Form/MediaUploadType/utils"
    import Translator from "bazinga-translator";

    const dispatch = createEventDispatcher()

    export let prototype: MediaFormEntry
    export let media: MultiUploadEntry
    export let neutral: boolean

    let previewUrl = undefined
    let sourceUrl = undefined
    let overRemove = false

    $: {
        if(media.uploadStatus?.previewUrl !== undefined) {
            previewUrl = media.uploadStatus?.previewUrl
            sourceUrl = media.uploadStatus?.previewUrl + "?fn="+(media.existingMedia?.name ?? media.file?.name ?? "")
        } else if (media.existingMedia){
            sourceUrl = media.existingMedia?.url + "?fn="+(media.existingMedia?.name ?? media.file?.name ?? "")
            if(isImage(media.existingMedia?.extension ?? "")) {
                previewUrl = media.existingMedia?.url
            } else {
                previewUrl = getPreviewIcon(media.existingMedia?.extension ?? "")
            }
        }
    }

    const formatBytes = (bytes: any) => {
        if (bytes == 0) return "0 B"

        const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(1024))

        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i]
    }

    const handleRemove = (): any => dispatch("remove", media)

    $: bgColor = media.uploadStatus?.percentage >= 100 ? 'bg-green-500' : 'bg-pink-500'
    $: textColor = media.uploadStatus?.percentage == 0 ? 'text-neutral-800' : media.uploadStatus?.percentage >= 100 ? 'text-green-500' : 'text-pink-500'

    $: uploadingTooltip = media.uploadStatus?.status === "uploading" ? Translator.trans("uploading_part")+` ${media.uploadStatus.chunk.current + 1}/${media.uploadStatus.chunk.total}` : ""
    $: mediaId = media.existingMedia?.id ?? media.uploadStatus?.id ?? ""
    $: mediaName = media.existingMedia?.name ?? media.file?.name ?? ""

    $: hasPreview = previewUrl !== undefined
    $: browserNative = hasBrowserNativePreview(media.existingMedia?.extension ?? "")
</script>

<div in:slide|local
     class="flex flex-row items-center {neutral ? 'bg-neutral-100' : 'bg-white'} media-container rounded-sm min-h-[45px] shadow-sm"
>
    {#if hasPreview}
        <img src={previewUrl} loading="lazy" alt="preview" class="w-8 h-8 p-0.5 {prototype.disabled ? '' : 'ml-2.5'} object-contain mr-3"/>
    {/if}

    <div class="flex flex-col w-full items-center">
        <div class="flex flex-row w-full items-center {(previewUrl === undefined && media.uploadStatus?.status === 'compressing') ? 'pl-4' : ''} {(previewUrl === undefined && media.uploadStatus?.status !== 'compressing') ? 'px-4' : ''} {media.uploadStatus?.percentage !== undefined ? 'pt-2' : 'py-2' }">
            <div class="flex flex-col gap-1 flex-1">
                <b class="text-sm text-east-bay text-ellipsis overflow-hidden mr-auto block break-all transition-colors">
                    {#if media.existingMedia}
                        {media.existingMedia.name}
                    {:else if media.file}
                        {media.file.name}
                    {/if}
                </b>

                {#if media.uploadStatus?.percentage === undefined && sourceUrl !== undefined}
                    <div class="flex gap-3 text-xs underline text-east-bay">
                        <a download={hasPreview ? mediaName : null} href={sourceUrl} class="hover:text-primary-600 transition-colors">{Translator.trans("action.download")}</a>
                        {#if browserNative}
                            <a target="_blank" href={sourceUrl} class="hover:text-primary-600 transition-colors">{Translator.trans("action.show")}</a>
                        {/if}
                    </div>
                {/if}
            </div>

            <span class="text-xs mx-3 whitespace-nowrap transition-colors">
                {#if media.existingMedia}
                    {formatBytes(media.existingMedia.size)}
                {:else if media.file}
                    {formatBytes(media.file.size)}
                {/if}
            </span>

            {#if media.uploadStatus?.status === "compressing"}
                <div class="h-4" use:tooltip={{content: Translator.trans("optimizing")}}>
                    <img class="w-full h-full object-contain" src="/image/puff.svg" alt="busy icon"/>
                </div>
            {/if}

            {#if media.uploadStatus !== undefined && media.uploadStatus?.status !== "compressing"}
                <div in:fade|local class="flex flex-row items-center text-xs w-full max-w-[65px]"
                     use:tooltip={{
                         placement: "top",
                         content: media.uploadStatus.status === "uploading"
                             ? uploadingTooltip
                             : media.uploadStatus.status === "uploaded"
                                ? Translator.trans("uploaded")
                                 : Translator.trans("awaiting_processing")
                     }}
                >
                    {#if media.uploadStatus.percentage !== undefined}
                        <b class="w-9 text-right mr-3 whitespace-nowrap {textColor}">{media.uploadStatus.percentage} %</b>

                        {#if media.uploadStatus.percentage >= 100}
                            <img src="/image/check-mark.svg" alt="uploaded"/>
                        {:else if media.uploadStatus.chunk.current <= media.uploadStatus.chunk.total}
                            <img src="/image/reload.svg" alt="uploading" class="animate-spin"/>
                        {/if}
                    {/if}
                </div>
            {/if}
        </div>
        {#if media.uploadStatus?.percentage !== undefined}
            <div class="pb-2 w-full pt-1">
                <div class="w-full bg-gray-200 rounded-md h-[3px] dark:bg-gray-300">
                    <div class="{bgColor} h-[3px] rounded-md" style="width: {media.uploadStatus.percentage}%"></div>
                </div>
            </div>
        {/if}
    </div>

    {#if !prototype.disabled}
        <button class="border-0 bg-transparent py-3 pl-3 pr-4 {media.uploadStatus !== undefined ? 'mb-2' : ''}"
                on:click|preventDefault={handleRemove}
                use:tooltip={{content: Translator.trans("remove"), placement: "right"}}
                on:mouseenter={() => overRemove = true}
                on:mouseleave={() => overRemove = false}
        >
            <img src="/image/trash-taste.svg" class="object-contain w-4" alt="remove"/>
        </button>
    {/if}

    <input name="{prototype.children.id.fullName.replace(prototype.name, media.prototypeIndex.toString())}" tabindex="-1" type="hidden" value={mediaId}/>
    <input name="{prototype.children.name.fullName.replace(prototype.name, media.prototypeIndex.toString())}" tabindex="-1" type="hidden" value={mediaName}/>
    <input name="{prototype.children.position.fullName.replace(prototype.name, media.prototypeIndex.toString())}" tabindex="-1" type="hidden" value={media.prototypeIndex + 1}/>
    <input name="{prototype.children.description.fullName.replace(prototype.name, media.prototypeIndex.toString())}" tabindex="-1" type="hidden" value=""/>
</div>