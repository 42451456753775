<script lang="ts">
    import {MediaUploadFormEntry, MultiUploadEntry} from "./interface"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import {throttle} from "lodash-es"
    import {getImagePreview, pickFileDialog, uploadFile, UploadStatus} from "./utils"
    import {fade} from "svelte/transition"
    import MultiUploadRow from "@shared/Form/MediaUploadType/MultiUploadRow.svelte"
    import {createEventDispatcher} from "svelte"
    import imageCompression from "browser-image-compression"
    import Translator from "bazinga-translator";

    export let entry: MediaUploadFormEntry
    export let neutral: boolean

    const dispatch = createEventDispatcher()

    let draggingFile = false
    let isOverUploadSpace = false
    let dragActiveTimeout
    let dropZoneEl: HTMLElement
    let pickingFile = false

    let uploadCounter = entry.children.collection.extra.counter

    let medias: MultiUploadEntry[] = entry.children.collection.children.filter(child => child.data !== null).map((child, index) => (({
        id: child.children.id.value,
        existingMedia: child.data,
        prototypeIndex: index,
    })))

    const handleDocumentDragEnter = throttle(() => {
        if (entry.errors.length) {
            entry.errors = []
        }

        draggingFile = true

        if (dragActiveTimeout) {
            clearTimeout(dragActiveTimeout)
        }

        dragActiveTimeout = setTimeout(() => {
            draggingFile = false
            isOverUploadSpace = false
            if (dragActiveTimeout) {
                clearTimeout(dragActiveTimeout)
            }
        }, 2000)
    }, 200, {
        leading: true,
        trailing: true,
    })

    const handleDropFile = (e: DragEvent): void => {
        draggingFile = false
        isOverUploadSpace = false

        const files: File[] = [...e.dataTransfer.files]

        const newMedias: MultiUploadEntry[] = []
        files.forEach(file => newMedias.push({
            file: file,
            id: {},
            extension: file.name.split(".").pop().toLowerCase(),
            prototypeIndex: ++uploadCounter,
        }))

        if (newMedias.length > 0) {
            medias = [...medias, ...newMedias]
        }
    }

    const handleDropzoneClick = (): void => {
        if(pickingFile) {
            return
        }

        const newMedias: MultiUploadEntry[] = []

        pickingFile = true
        pickFileDialog(true).then(files => {
            files.forEach(file => newMedias.push({
                file: file,
                id: {},
                extension: file.name.split(".").pop().toLowerCase(),
                prototypeIndex: ++uploadCounter,
            }))
        }).then(() => {
            if (newMedias.length > 0) {
                medias = [...medias, ...newMedias]
            }
        }).catch(() => {}).finally(() => pickingFile = false)
    }

    const handleRemoveMedia = (event: CustomEvent<MultiUploadEntry>): void => {
        const mediaIndex = medias.findIndex(media => media.id === event.detail.id)
        if (mediaIndex !== -1) {
            medias.splice(mediaIndex, 1)

            // re-render
            medias = medias
        }
    }

    const handlePostCompression = async (index: number, mediaToUpload: MultiUploadEntry): Promise<void> => {
        //@ts-ignore
        mediaToUpload.uploadStatus.status = "initializing"
        medias[index] = mediaToUpload

        // trigger re-render
        medias = medias

        await getImagePreview(mediaToUpload.file as File, mediaToUpload.extension as string).then(previewUrl => {
            const mediaToAddPreviewToIndex = medias.findIndex(media => media.id === mediaToUpload.id)
            if (mediaToAddPreviewToIndex !== -1) {
                const mediaToAddPreviewTo = medias[mediaToAddPreviewToIndex]
                if (mediaToAddPreviewTo !== undefined) {
                    if (mediaToAddPreviewTo.uploadStatus === undefined) {
                        mediaToAddPreviewTo.uploadStatus = {} as UploadStatus
                    }

                    mediaToAddPreviewTo.uploadStatus.previewUrl = previewUrl

                    medias[mediaToAddPreviewToIndex] = mediaToAddPreviewTo

                    // trigger re-render
                    medias = medias
                }
            }
        })

        await uploadFile({
            id: mediaToUpload.existingMedia?.id,
            file: mediaToUpload.file,
            extension: mediaToUpload.extension,
            storageType: entry.extra.storage_type,
            onProgress: (status) => {
                const mediaToUpdateIndex = medias.findIndex(media => media.id === mediaToUpload.id)
                if (mediaToUpdateIndex === -1) {
                    return true
                }

                const mediaToUpdateStatus = medias[mediaToUpdateIndex]
                if (mediaToUpdateStatus.uploadStatus === undefined) {
                    mediaToUpdateStatus.uploadStatus = {} as UploadStatus
                }

                mediaToUpdateStatus.uploadStatus = {
                    ...mediaToUpdateStatus.uploadStatus,
                    ...status,
                    percentage: Math.round((status.chunk.current / status.chunk.total) * 100),
                }

                medias[mediaToUpdateIndex] = mediaToUpdateStatus

                // trigger re-render
                medias = medias
            }
        }, (name: string, data: any) => dispatch(name, data))
    }

    $: {
        medias // runs everytime `medias` change

        const mediaToUploadIndex = medias.findIndex(media => media.file !== undefined && media.uploadStatus?.status !== "uploaded")
        if (mediaToUploadIndex !== -1) {
            const mediaToUpload = medias[mediaToUploadIndex]

            if (mediaToUpload.uploadStatus?.status == null) {
                if (mediaToUpload.uploadStatus === undefined) {
                    mediaToUpload.uploadStatus = {} as UploadStatus
                }

                if(["jpg", "jpeg", "png"].includes(mediaToUpload.extension ?? "")) {
                    const nameBackup = mediaToUpload.file?.name
                    mediaToUpload.uploadStatus.status = "compressing"
                    medias[mediaToUploadIndex] = mediaToUpload

                    imageCompression(mediaToUpload.file as File, {
                        useWebWorker: true,
                        preserveExif: true,
                        alwaysKeepResolution: true,
                    }).then(file => {
                        //@ts-ignore
                        file.name = nameBackup
                        mediaToUpload.file = file
                    }).finally(() => handlePostCompression(mediaToUploadIndex, mediaToUpload))
                } else {
                    handlePostCompression(mediaToUploadIndex, mediaToUpload)
                }
            }
        }
    }
</script>

<svelte:body on:dragenter={handleDocumentDragEnter}/>

{#if entry.label !== null}
    <label class="font-bold text-neutral-800 relative mb-1" {...entry.labelAttr}>
        {@html entry.label}
        {#if !entry.disabled && entry.required}
            <span class="absolute ml-1 text-red-800">*</span>
        {/if}
    </label>
{/if}

<div class="flex flex-col">
    {#if !entry.disabled}
        <div class="flex bg-neutral-100 rounded-md w-full max-w-md {entry.extra.slim ? 'h-10' : 'h-48'} relative overflow-hidden cursor-pointer"
             bind:this={dropZoneEl}
             on:click={handleDropzoneClick}
             on:dragenter={() => isOverUploadSpace = true}
             on:dragleave={() => isOverUploadSpace = false}
             on:dragover|preventDefault
             on:drop|preventDefault={handleDropFile}
        >
            {#if draggingFile}
                <div transition:fade|local class="pointer-events-none absolute left-0 top-0 right-0 bottom-0">
                    <div class='circle xxlarge shade1'></div>
                    <div class='circle xlarge shade2'></div>
                    <div class='circle large shade3'></div>
                    <div class='circle medium shade4'></div>
                    <div class='circle small shade5'></div>
                </div>
            {/if}

            <div class="absolute left-0 top-0 right-0 bottom-0 flex">
                <div class="flex my-auto flex-1 z-10 pointer-events-none select-none items-center {entry.extra.slim ? 'flex-row' : 'flex-col justify-center'}">
                    <img alt="upload file icon"
                         class="object-contain"
                         src="/image/upload.svg"
                         class:h-5={entry.extra.slim}
                         class:ml-4={entry.extra.slim}
                         class:mb-3={!entry.extra.slim}
                    />
                    <span class="text-east-bay tracking-tight max-w-sm leading-tight {entry.extra.slim ? 'text-xs px-4 text-left' : 'text-sm text-center'}">
                        {Translator.trans("drop_files_here_clean")}
                    </span>
                </div>
            </div>
        </div>
        {#if entry.help !== null}
            <small class="mt-2">{@html entry.help}</small>
        {/if}
        <FormTypeErrors class="mt-2" errors={entry.errors}/>
    {/if}


    {#if medias.length}
        <div class="flex flex-col gap-1.5 max-w-md {(entry.disabled || entry.extra.slim) ? 'mt-4' : 'mt-8'} overflow-auto max-h-[400px] scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-thumb-rounded-md scrollbar-w-1.5 pr-2.5">
            {#each medias as media (media.id)}
                <MultiUploadRow
                        media={media}
                        neutral={neutral}
                        prototype={entry.children.collection.extra.prototype}
                        on:remove={handleRemoveMedia}
                />
            {/each}
        </div>
    {/if}
</div>

<style lang="scss">
    .circle {
        @apply bg-white absolute rounded-full;
        animation: ripple 6s infinite ease-in-out;
    }

    .small {
        width: 100px;
        height: 100px;
        left: -50px;
        bottom: -50px;
    }

    .medium {
        width: 200px;
        height: 200px;
        left: -100px;
        bottom: -100px;
    }

    .large {
        width: 300px;
        height: 300px;
        left: -150px;
        bottom: -150px;
    }

    .xlarge {
        width: 400px;
        height: 400px;
        left: -200px;
        bottom: -200px;
    }

    .xxlarge {
        width: 500px;
        height: 500px;
        left: -250px;
        bottom: -250px;
    }

    .shade1 {
        opacity: 0.1;
    }

    .shade2 {
        opacity: 0.2;
    }

    .shade3 {
        opacity: 0.3;
    }

    .shade4 {
        opacity: 0.4;
    }

    .shade5 {
        opacity: 0.5;
    }


    @keyframes ripple {
        0% {
            transform: scale(0.8);
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(0.8);
        }
    }

    @keyframes ripple2 {
        0% {
            transform: scale(0.6);
        }

        50% {
            transform: scale(1.0);
        }

        100% {
            transform: scale(0.8);
        }
    }

</style>
